<div id="review-form">
    <h1>Add a sake review.</h1>
    <form [formGroup]="addReviewForm"><div>
            <div><mat-form-field class="full_width"><input matInput formControlName="bottleName" placeholder="Name of the bottle"></mat-form-field></div>
            <div id="input-area">
                <input style="display: none" #fileInput type="file" accept="image/*" (change)="pictureUpload($event)">
                <button mat-raised-button color="primary" class="file-select-button, full_width" (click)="onClickFileInputButton()">
                    <mat-icon>attach_file</mat-icon>
                     Upload a picture
                   </button>
                <div class="preveiew_image"><img class="bottleImage" [src]="uploadFileUrl"></div>
            </div>
            <div>
                <section>
                    <h4>Rating</h4>
                    <mat-button-toggle-group formControlName="rating" aria-label="Font Style">
                        <mat-button-toggle *ngFor="let rating_option of rating_options" value="{{rating_option}}">{{rating_option}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </section>
            </div>
            <div><mat-form-field class="full_width">
                <textarea matInput formControlName="comment" placeholder="Review"></textarea>
            </mat-form-field></div>
            <h2>Detail of Sake</h2>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Classification</mat-label>
                    <input type="text" matInput formControlName="classification" [matAutocomplete]="auto_classification">
                    <mat-autocomplete #auto_classification="matAutocomplete">
                    <mat-option *ngFor="let option of classification_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div><mat-form-field class="full_width"><input matInput formControlName="brewery" placeholder="Brewery"></mat-form-field></div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Rice</mat-label>
                    <input type="text" matInput formControlName="rice" [matAutocomplete]="auto_rice">
                    <mat-autocomplete #auto_rice="matAutocomplete">
                    <mat-option *ngFor="let option of rice_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Rice-polishing rate</mat-label>
                    <input type="text" matInput formControlName="rice_polishing_rate" [matAutocomplete]="auto_rice_polishing_rate">
                    <mat-autocomplete #auto_rice_polishing_rate="matAutocomplete">
                    <mat-option *ngFor="let option of rice_polishing_rate_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Alcohol content</mat-label>
                    <input type="text" matInput formControlName="alcohol_content" [matAutocomplete]="auto_alcohol_content">
                    <mat-autocomplete #auto_alcohol_content="matAutocomplete">
                    <mat-option *ngFor="let option of alcohol_content_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Sake Meter Value</mat-label>
                    <input type="text" matInput formControlName="sake_meter_value" [matAutocomplete]="auto_sake_meter_value">
                    <mat-autocomplete #auto_sake_meter_value="matAutocomplete">
                    <mat-option *ngFor="let option of sake_meter_value_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Acidity</mat-label>
                    <input type="text" matInput formControlName="acidity" [matAutocomplete]="auto_acidity">
                    <mat-autocomplete #auto_acidity="matAutocomplete">
                    <mat-option *ngFor="let option of acidity_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Amino Acid Content</mat-label>
                    <input type="text" matInput formControlName="amino_acid_content" [matAutocomplete]="auto_amino_acid_content">
                    <mat-autocomplete #auto_amino_acid_content="matAutocomplete">
                    <mat-option *ngFor="let option of amino_acid_content_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full_width">
                    <mat-label>Sake yeast</mat-label>
                    <input type="text" matInput formControlName="sake_yeast" [matAutocomplete]="auto_sake_yeast">
                    <mat-autocomplete #auto_sake_yeast="matAutocomplete">
                    <mat-option *ngFor="let option of sake_yeast_options" value="{{option}}">
                        {{option}}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <h2>Apparance of Sake</h2>
            <div>
                <section>
                    <h4>Clearness</h4>
                    <mat-button-toggle-group formControlName="clearness" aria-label="Font Style">
                        <mat-button-toggle *ngFor="let option of clearness_options" value="{{option}}">{{option}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </section>
            </div>
            <div>
                <section>
                    <h4>Bubble level</h4>
                    <mat-button-toggle-group formControlName="bubble_level" aria-label="Font Style">
                        <mat-button-toggle *ngFor="let option of bubble_level_options" value="{{option}}">{{option}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </section>
            </div>
            <div>
                <section>
                    <h4>Green level</h4>
                    <mat-button-toggle-group formControlName="green_level" aria-label="Font Style">
                        <mat-button-toggle *ngFor="let option of green_level_options" value="{{option}}">{{option}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </section>
            </div>
            <div>
                <section>
                    <h4>Yellow level. (by Maillard reaction)</h4>
                    <mat-button-toggle-group formControlName="yellow_level" aria-label="Font Style">
                        <mat-button-toggle *ngFor="let option of yellow_level_options" value="{{option}}">{{option}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </section>
            </div>
            <div>
            <h2>Aroma of Sake</h2>
            <mat-form-field class="full_width">
                <mat-label>Aroma</mat-label>
                <mat-chip-list #aroma_chipList aria-label="Aroma selection">
                  <mat-chip
                    *ngFor="let aroma of aromas"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="remove_aroma(aroma)">
                    {{aroma}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="Add aromas..."
                    #aromaInput
                    [formControl]="aromaCtrl"
                    [matAutocomplete]="auto_aroma"
                    [matChipInputFor]="aroma_chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add_aroma($event)">
                </mat-chip-list>
                <mat-autocomplete #auto_aroma="matAutocomplete" (optionSelected)="selected_aroma($event)">
                  <mat-option *ngFor="let aroma of filteredAroma | async" [value]="aroma">
                    {{aroma}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            <button mat-raised-button color="primary" class="full_width" (click)="addReview()">Submit the review</button>
            </div>
        </div></form>
  </div>