import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips'; 

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ShowReviewComponent } from './show-review/show-review.component';
import { AddReviewComponent } from './add-review/add-review.component';

const routes:Routes = [
  { path: '', component: HomeComponent },
  { path: 'show-review/:id', component: ShowReviewComponent },
  { path: 'add', component: AddReviewComponent }
];

const fire_config = {
  apiKey: "AIzaSyCESDZ6xqnU6IJEWM7PGUL5-N0r5ohtv0M",
  authDomain: "sakewave.com",
  projectId: "kikisake-85672",
  storageBucket: "kikisake-85672.appspot.com",
  messagingSenderId: "664171957877",
  appId: "1:664171957877:web:d7a1daaf77c55f15ac723c"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ShowReviewComponent,
    AddReviewComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatDividerModule,
    MatCardModule,
    MatListModule,
    MatRadioModule,
    MatSliderModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(fire_config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
  ],
  providers: [
    { provide: BUCKET, useValue: 'kikisake-85672.appspot.com' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }