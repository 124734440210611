<div id="preview-reviews">
    <h1>My sake review list</h1>
    <mat-card *ngFor="let item of data" class="review-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{item.bottle_name}}</mat-card-title>
        <mat-card-subtitle>{{item.brewery}}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src={{item.photo}} alt={{item.bottle_name}}>
      <mat-card-content>
        <p>Rating : {{item.rating}}</p>
        <p>{{item.comment}}</p>
      </mat-card-content>
      <mat-card-actions><button mat-button [routerLink]="['show-review',item.id]">Detail</button></mat-card-actions>
    </mat-card>
  </div>
  