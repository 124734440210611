import { Component, OnInit, SystemJsNgModuleLoaderConfig } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  message:string;
  data:any;

  constructor(private store:AngularFirestore, private fns:AngularFireFunctions, private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.message = 'wait...';
    this.data = null;
    this.getReviews();
  }

  getReviews() {
    this.store.collection('reviews', 
        ref => ref.orderBy('posted','desc').limit(10))
      .valueChanges({ idField: "id" })
      .subscribe(value=>{
        this.data = value;
      },
      error=>{
        this.message = "(can't get data...)";
        this.data = null;
      });
  }
}