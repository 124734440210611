import { Component, OnInit, ViewChild , ElementRef} from '@angular/core';
import * as firebase from "firebase";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router'
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, of} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.css']
})
export class AddReviewComponent implements OnInit {
    message:string;
    reviewComment:string;
    dryness:number;
    key:string;
    uploadFileUrl:string = "https://storage.googleapis.com/kikisake-85672.appspot.com/media/bottle.png";
    bubble_level:string;
    classification_options = ['Junmai ginjou', 'ginjo'];
    rating_options = ['★', '★★', '★★★', '★★★★', '★★★★★']; 
    addReviewForm:FormGroup;
    rice_polishing_rate_options = [];
    alcohol_content_options = [];
    sake_meter_value_options = [];
    acidity_options = [];
    amino_acid_content_options = [];
    rice_options = ["Gohyaku"];
    sake_yeast_options = ["Kyokai 1 gou"];
    clearness_options = ["Clear","Relatively clear","Relatively cloudy","Cloudy"];
    bubble_level_options = ["None","Very few bubble","Slightly sparkling","Sparkling"];
    green_level_options = ["None","Light green","Pale green"];
    yellow_level_options = ["None","Light yellow","Yellow","Bright yellow","Gold"];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    aromas: string[] = [];
    aroma_options: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
    filteredAroma: Observable<string[]>;
    aromaCtrl = new FormControl();
  
    @ViewChild('fileInput')
    fileInput;  

    @ViewChild('aromaInput') aromaInput: ElementRef<HTMLInputElement>;

    constructor(private store:AngularFirestore, 
        private fns:AngularFireFunctions, 
        private afAuth: AngularFireAuth,
        private storage: AngularFireStorage,
        private router: Router)
        {
          this.aromaCtrl.valueChanges.subscribe(search => {
            this.filteredAroma = of(this.aroma_options.filter(item =>
              item.toLowerCase().includes(search)
            ));
          });
        }

  
    ngOnInit() {
      this.addReviewForm = new FormGroup({
        bottleName: new FormControl(``),
        rating: new FormControl(``),
        classification: new FormControl(``),
        comment: new FormControl(``),
        brewery: new FormControl(``),
        rice: new FormControl(``),
        rice_polishing_rate: new FormControl(``),
        alcohol_content: new FormControl(``),
        sake_meter_value: new FormControl(``),
        acidity: new FormControl(``),
        amino_acid_content: new FormControl(``),
        sake_yeast: new FormControl(``),
        clearness: new FormControl(``),
        bubble_level: new FormControl(``),
        green_level: new FormControl(``),
        yellow_level: new FormControl(``)
      });
      for (let i = 10; i <= 100; i += 5) {
        this.rice_polishing_rate_options.push(i);
      }
      for (let i = 10; i <= 20; i += 1) {
        this.alcohol_content_options.push(i);
      }
      for (let i = -10; i <= 10; i += 1) {
        this.sake_meter_value_options.push(i);
      }
      for (let i = 0; i <= 20; i += 1) {
        this.acidity_options.push(i/10);
      }
      for (let i = 10; i <= 20; i += 1) {
        this.amino_acid_content_options.push(i/10);
      }
      this.filteredAroma = of(this.aroma_options.filter(item =>
          item.toLowerCase()
        ));
    }
  
    addReview() {
      this.store.collection('bottles',
      ref => ref.where('name','==',this.addReviewForm.value.bottleName))
      .snapshotChanges()
      .subscribe(value=>{
        if (value[0] == undefined) {
          const user = firebase.default.auth().currentUser;
          this.key = this.store.createId();
          const obj = {
            name: this.addReviewForm.value.bottleName,
            posted:new Date().getTime(),
            uid: user.uid,
            photo: this.uploadFileUrl,
            classification: this.addReviewForm.value.classification,
            brewery: this.addReviewForm.value.brewery,
            rice: this.addReviewForm.value.rice,
            rice_polishing_rate: this.addReviewForm.value.rice_polishing_rate,
            alcohol_content: this.addReviewForm.value.alcohol_content,
            sake_meter_value: this.addReviewForm.value.sake_meter_value,
            acidity: this.addReviewForm.value.acidity,
            amino_acid_content: this.addReviewForm.value.amino_acid_content,
            sake_yeast: this.addReviewForm.value.sake_yeast,
            clearness: this.addReviewForm.value.clearness,
            bubble_level: this.addReviewForm.value.bubble_level,
            green_level: this.addReviewForm.value.green_level,
            yellow_level: this.addReviewForm.value.yellow_level
          };
          this.store.collection('bottles').add(obj);
        } else {
          const key = value[0].payload.doc.id;
        }
      },
      error=>{
          console.log(error);
      });
      const user = firebase.default.auth().currentUser;
      const obj = {
        bottle_name: this.addReviewForm.value.bottleName,
        uid: user.uid,
        comment: this.addReviewForm.value.comment,
        photo: this.uploadFileUrl,
        posted:new Date().getTime(),
        rating: this.addReviewForm.value.rating,
        classification: this.addReviewForm.value.classification,
        brewery: this.addReviewForm.value.brewery,
        rice: this.addReviewForm.value.rice,
        rice_polishing_rate: this.addReviewForm.value.rice_polishing_rate,
        alcohol_content: this.addReviewForm.value.alcohol_content,
        sake_meter_value: this.addReviewForm.value.sake_meter_value,
        acidity: this.addReviewForm.value.acidity,
        amino_acid_content: this.addReviewForm.value.amino_acid_content,
        sake_yeast: this.addReviewForm.value.sake_yeast,
        clearness: this.addReviewForm.value.clearness,
        bubble_level: this.addReviewForm.value.bubble_level,
        green_level: this.addReviewForm.value.green_level,
        yellow_level: this.addReviewForm.value.yellow_level,
        aromas: this.aromas
      };
      this.store.collection('reviews').add(obj).then(value => {
        this.router.navigate(['']);
      });
    }

    pictureUpload(event){
      const file: File = event.target.files[0];
      let fileName = '';;
      let str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let n = 30;
      for(let i=0; i<n; i++){
        fileName += str[~~(Math.random() * str.length)];
      }
      fileName += file.name.substr(file.name.lastIndexOf('.'));
      const filePath = 'upload_files/' + fileName;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      task.snapshotChanges().pipe(
        finalize(() => this.uploadFileUrl = "https://storage.googleapis.com/kikisake-85672.appspot.com/" + 'upload_files/' + fileName )
      )
      .subscribe()
    }

    onClickFileInputButton(): void {
      this.fileInput.nativeElement.click();
    }

    add_aroma(event: MatChipInputEvent): void {
      const input = event.input;
      const value = (event.value || '').trim();
  
      // Add our fruit
      if (value) {
        this.aromas.push(value);
      }

      if (input) {
        input.value = '';
      }
    }
  
    remove_aroma(fruit: string): void {
      const index = this.aromas.indexOf(fruit);
  
      if (index >= 0) {
        this.aromas.splice(index, 1);
      }
    }
  
    selected_aroma(event: MatAutocompleteSelectedEvent): void {
      this.aromas.push(event.option.viewValue);
      this.aromaInput.nativeElement.value = '';
      this.aromaCtrl.setValue(null);
    }

  }