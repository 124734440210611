import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export interface Review { bottle_name: string; }

@Component({
  selector: 'app-show-review',
  templateUrl: './show-review.component.html',
  styleUrls: ['./show-review.component.css']
})
export class ShowReviewComponent implements OnInit {
  id:string;
  data:any;
  comments:any;
  key:string;
  message:string = 'wait...';
  comment:string;
  private itemDocument: AngularFirestoreDocument<Review>;
  item: Observable<Review>;

  constructor(private route: ActivatedRoute, 
      private afAuth: AngularFireAuth, 
      private store:AngularFirestore) {
    const param = route.snapshot.paramMap;
    this.id = param['params']['id'];
    this.itemDocument = store.doc<Review>('reviews/' + this.id );
    this.item = this.itemDocument.valueChanges();
  }

  ngOnInit() {
    
  }

}